import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles, Container } from '@material-ui/core';

import { Hero } from '../components/General/Hero';
import { HeroImageRight } from '../components/General/HeroImageRight';
import { HeroVideo } from '../components/General/HeroVideo';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
import { Testimonials } from '../components/Testimonials';
import { CustomerLogos } from '../components/CustomerLogos';
import { CtaCard } from '../components/General/CtaCard';
// import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { Resources } from '../components/General/Resources';
import { WhyBody } from '../components/WhyRg/WhyBody';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
  testimonialBackground: {
    backgroundSize: 'cover',
    height: '600px',
    width: '99vw',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      height: '500px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '400px',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: 'left 60px',
    },
  },
  testimonialContainer: {
    marginTop: '-35rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '-30rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-25rem',
    },
  },
  testimonialTitle: {
    color: theme.white,
    fontWeight: 700,
    padding: '2rem 0',
  },
  formCont: {
    padding: '12rem 0 24rem 0',
    [theme.breakpoints.down('md')]: {
      padding: '8rem 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '5rem 0',
    },
  },
}));

const WhyRealGreen = ({ data, location }) => {
  const whyRg = data.whyRg.edges[0].node;
  const classes = useStyles();

  const {
    hero,
    heroImage,
    heroVideo,
    heroAlignCenter,
    metaDescription,
    metaTitle,
    pardotUrl,
    contactForm,
    _rawOverviewContent,
    introCards,
    awardsHeader,
    awards,
    centerSectionBg,
    _rawCenterSectionLeft,
    centerSectionCtaText,
    centerSectionCards,
    whyRGCallout,
    formBgImage,
    testimonial,
    testimonialTitle,
    customerLogos,
    customerLogosHeader,
    resources,
    resourceTitle,
    _rawResourceBody,
    testimonialBackground,
  } = whyRg;

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      {heroAlignCenter && heroVideo ? (
        <div className={classes.heroVideo}>
          <Hero hero={hero} feature />
          <Container>
            <HeroVideo wistiaLink={heroVideo} />
          </Container>
        </div>
      ) : heroAlignCenter ? (
        <Hero hero={hero} feature />
      ) : (
        <HeroImageRight
          hero={hero}
          heroImage={heroImage?.asset?.gatsbyImageData}
          whyRg
          feature
        />
      )}
      <WhyBody
        intro={_rawOverviewContent}
        introCards={introCards}
        awardsArray={awards || []}
        awardsHeader={awardsHeader}
        bg={centerSectionBg}
        leftContent={_rawCenterSectionLeft}
        ctaText={centerSectionCtaText}
        centerSectionCards={centerSectionCards}
      />
      {whyRGCallout.ctaHeader ? (
        <Container>
          <CtaCard
            background={whyRGCallout.ctaBackground}
            header={whyRGCallout.ctaHeader}
            subheader={whyRGCallout.ctaSubheader}
            ctaText={whyRGCallout.ctaText}
            internalLink={whyRGCallout.internalLink}
            ctaLink={whyRGCallout.ctaLink}
          />
        </Container>
      ) : null}
      <>
        <div
          className={classes.testimonialBackground}
          style={{
            backgroundImage: `url(${testimonialBackground.asset.gatsbyImageData.images.fallback.src})`,
          }}
        />
        <WaveUpSVG fill='#FFF' />
        <div className={classes.testimonialContainer}>
          <Testimonials
            header={testimonialTitle}
            testimonials={testimonial}
            background
          />
        </div>
      </>
      <Container style={{ marginBottom: '7rem' }}>
        <CustomerLogos
          customerLogosArray={customerLogos}
          customerLogosHeader={customerLogosHeader}
        />
      </Container>
      {/* <WaveDownSVG height='213' width='100%' fill='white' /> */}

      <div
        className={classes.formCont}
        style={{
          backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
          backgroundSize: 'cover',
        }}
      >
        <Form
          pardotUrl={pardotUrl}
          contactForm={contactForm}
          privacy
          modal={false}
          location={location}
        />
      </div>
      {/* <WaveUpSVG height='213' width='100%' fill='white' /> */}
      <Container>
        <Resources
          header={resourceTitle}
          subheader={_rawResourceBody}
          resources={resources}
          noWave
        />
      </Container>
      <div>
        <WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
      </div>
    </>
  );
};

export const query = graphql`
  query WhyRealGreenQuery {
    whyRg: allSanityWhyRg {
      edges {
        node {
          title
          metaTitle
          metaDescription
          hero {
            _rawContent
            backgroundImage {
              asset {
                gatsbyImageData(placeholder: BLURRED, height: 100)
              }
            }
            mobileBackgroundImage {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            ctaButtonText
            ctaButtonLink
            internalLink
          }
          heroImage {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          heroVideo
          heroAlignCenter
          _rawOverviewContent
          introCards {
            header
            icon
            _rawContent
            accentColor {
              hexValue
            }
          }
          centerSectionBg {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          _rawCenterSectionLeft
          centerSectionCtaText
          centerSectionCards {
            cardTitle
            header
            icon
            _rawBody
            accentColor {
              hexValue
            }
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          whyRGCallout {
            ctaBackground {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            ctaHeader
            ctaSubheader
            ctaText
            internalLink
            ctaLink
          }
          awardsHeader
          awards {
            title
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          testimonialTitle
          testimonial {
            title
            header
            testimonialLogo {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            company
            testimonialText
            nameAndTitle
            videoVariant
            image {
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  height: 450
                  width: 775
                  placeholder: BLURRED
                )
              }
            }
          }
          testimonialBackground {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          customerLogosHeader
          customerLogos {
            logo {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          resourceTitle
          _rawResourceBody
          resources {
            title
            blurb
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            ctaText
            ctaLink
          }
          contactForm {
            header
            privacyPolicy
          }
          formBgImage {
            asset {
              gatsbyImageData
            }
          }
          pardotUrl
        }
      }
    }
  }
`;

export default WhyRealGreen;
